<template>
  <div :class="'skin_theme_' + skin_theme">
    <div class="index_page_v2">
      <!-- 头部 -->
      <div class="main_header">
        <div class="header-c">
          <headerV3 />
        </div>
      </div>

      <div class="main_header_buwei"></div>

      <!-- 身体 -->
      <div class="main_box">
        <div class="main">
          <mainV2 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerV3 from "../../../views/index/search/V2c/c/headerV3.vue";
import mainV2 from "@/views/index/search/V2c/mainV2.vue";

export default {
  components: {
    headerV3,
    mainV2,
  },
  data() {
    return {
      isChangeTheme: false,
    };
  },
  mounted() {},
  methods: {},
  computed: {
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1300px) {
  .main_header {
    position: fixed;
    top: 0;
    z-index: 100;
  }
  .main_header_buwei {
    height: 80px;
  }
}

body {
  --xzd_color1: #f3f5ff !important;
  --xzd_color2: #ebefff !important;
  --xzd_color3: #333 !important;
  --xzd_color4: #615bff !important;
  --xzd_color5: #aeabff !important;
  --xzd_color6: #4943ff !important;
}

.index_page_v2 {
  width: 100vw;
  min-width: 1300px;
  height: 100vh;
  overflow: auto;

  .main_header {
    width: 100%;
    z-index: 100;

    .header-c {
      width: 1300px;
      margin: 0 auto;
    }

    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  }

  .main_box {
    z-index: 99;
  }
  .main {
    display: flex;
    width: 1274px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    border-radius: 20px 0 0 0;
  }
}

.skin_theme_one {
  .index_page_v2,
  .main_header,
  .main_box,
  .change_theme {
    background: #fff;
  }

  .change_theme,
  .main_header {
    color: #615bff;
  }
  .right {
    background: #ebefff;
  }
}
.skin_theme_two {
  .index_page_v2,
  .main_header,
  .main_box {
    background: #151417;
  }
  .main_header {
    color: #fff;
    box-shadow: 0 1px 2px #fff;
  }

  .right {
    background: #232227;
  }
}
</style>